export default {
  async insert (Vue, formData, idcliente, idclientePotencial) {
    const resp = await Vue.$api.call(
      'clienteDireccion.insert',
      {
        values: {
          idcliente_potencial: idclientePotencial,
          idcliente: idcliente,
          idtdireccion: formData.idtdireccion,
          calle: formData.calle,
          num: formData.num,
          cp: formData.cp,
          idpais: formData.idpais,
          idtvia: formData.idtvia,
          otros_datos: formData.otros_datos,
          idzona_comercial: formData.idzona,
          idzona_tecnico: formData.idzona,
          idzona_vigilancia: formData.idzona,
          idprovincia: formData.idprovincia,
          idlocalidad: formData.idlocalidad,
          observacion: formData.observacion,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
