<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <cliente-direccion-form
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ClienteDireccionForm from '../components/ClienteDireccionForm'
import Data from './ClienteDireccionAddData'

export default {
  components: {
    ClienteDireccionForm
  },
  mixins: [formPageMixin],
  props: {
    tipo: {
      type: String,
      default: '',
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir dirección'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const inserted = await Data.insert(
        this, this.formData, this.routeParams.idcliente, this.routeParams.idcliente_potencial
      )
      if (this.tipo === 'cliente_potencial') {
        this.$appRouter.replace({
          name: 'direcciones__clientes-potenciales-cliente-direccion-view',
          params: {
            idcliente_direccion: inserted.idcliente_direccion,
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (this.tipo === 'cliente') {
        this.$appRouter.replace({
          name: 'direcciones__clientes-cliente-direccion-view',
          params: {
            idcliente_direccion: inserted.idcliente_direccion,
            idcliente: this.routeParams.idcliente,
          },
        })
      }
    },
  },
}
</script>
